import React from "react";

export default function Aboutus() {
  return (
    <>
      <h4>ห้างทองธนะพัฒน์ : THANAPHAT GOLD SMITH</h4>
      <p>- จำหน่าย-รับทำ ทองรูปพรรณ ทองคำแท้ 96.5% มาตรฐาน สคบ.รับออมเงินได้ทอง ทั้งออนไลน์และหน้าร้าน -</p>
    </>
  );
}
