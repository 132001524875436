import React from "react";

export default function Contactus() {
    return (
        <>
            <h4>ติดต่อเราได้ที่: </h4>
            <p><span>🏠 </span>159/1 ถ.ทะเลหลวง ต.บ่อยาง อ.เมือง จ.สงขลา 90000</p>
            <p><span>📍 </span>สงขลา ประเทศไทย</p>
            <p><span>📞 </span>---</p>
        </>
    );
}